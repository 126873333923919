import React from 'react';
import '../styling/contact.css';

const Contact = () => {
  return (
  <div>
<form action="https://formsubmit.co/suevision@virginmedia.com" method="POST" className="contact-form">
<h1 className="contact-title">Get in touch</h1>
    <label for="name">Name</label>
    <input type="text" id="name" name="name" required />
    
    <label for="email">Email</label>
    <input type="email" id="email" name="email" required />
    
    <label for="message">Message</label>
    <textarea id="message" name="message" rows="5" required></textarea>
    
    {/* <!-- Hidden field to prevent spam -->
    <input type="hidden" name="_next" value="https://your-site.com/thank-you.html">
    <input type="hidden" name="_captcha" value="false">  <!-- Disables CAPTCHA --> */}

    <button type="submit">SEND</button>
  </form>
 
</div>


  )
};

export default Contact;
