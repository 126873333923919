
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link} from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';

import Footer from './components/Footer';
import './App.css';


function App() {
  return (
    <Router>
      <div className="app">
      <nav className="navbar">
        <div className="logo">Sue Morris</div>
           <ul className="nav-links">
              <li><Link to="/" className="link">Home</Link></li>
              <li><Link to="/contact" className="link">Contact</Link></li>
          </ul>
      </nav>

        <div className="main">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
     </div>
      <Footer />
   </div>
    </Router>
    
  );
}

export default App;


