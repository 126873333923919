import React from 'react';
import '../styling/footer.css';
import Insta from '../asset/insta.svg'
import Link from '../asset/linkedin.svg'

function Footer() {
  return (
    <footer className="footer">
         <span>© {new Date().getFullYear()} Sue Morris. All rights reserved.</span>
         <div className="socials"> 
         <a href="https://www.instagram.com/suemorris_official/" target="_blank" rel="noopener noreferrer"><img src={Insta} alt="Sue Morris" className="social"/></a>
         <a href="https://www.linkedin.com/in/sue-morris-9996a0aa/" target="_blank" rel="noopener noreferrer"><img src={Link} alt="Sue Morris" className="social"/></a>
         </div>
        </footer>
  );
}

export default Footer;